// @flow

import React, { useState, useEffect, useContext } from 'react';
import { marked } from 'marked';
import ReactHTMLParser from 'react-html-parser';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { ImportantInformation } from '@latitude/important-information';
import { Box } from '@latitude/box';
import { Hero } from '@latitude/hero';
import { FeaturesSlider } from '@latitude/features-slider';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { StickyCta } from '@latitude/sticky-cta';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { Footer } from '@latitude/footer';
import {
  BREAKPOINT,
  COLOR
} from '@latitude/core/utils/constants';
import { NotificationBanner } from '@latitude/banner';
import HeroBranded from '../../components/HeroBranded/HeroBranded';
import Layout from '../../components/layout';

import { HeroText } from '@latitude/hero/Hero.styles';
import PageData from '../../data/pages/debt-consolidation-loans.json';
import footerData from '../../data/json/footer.json';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import Button from '../../components/Button/Button';
import Lframe from '../../components/Lframe/Lframe';
import LoanRates from '../../components/LoanRates/LoanRates1';
import CalculatorTile from '../../components/Calculators/CalculatorTile';
import { featuresSliderIE11Fix } from '../../utils/stylesUtils';
import LoanProcessList from '../../components/LoanProcessList/LoanProcessList';
import { LOAN_PURPOSE } from '../../utils/constants';
import EstimateRateWidgetSection from '../../components/EstimateRateWidgetSection/EstimateRateWidgetSection1';
import useApplyUrl from '../../hooks/useApplyUrl';
import SoftQuoteContext from '../../context/SoftQuoteContext';
import HowToApplyEligibility from '../../components/HowToApplyEligibility/HowToApplyEligibility1';
import Faq from '../../components/Faq/Faq';
import FeesAndCharges from './_LoanFeesCharges';
import Section from '@latitude/section';
import heroImage from '../../images/pl-debt-con_hero-image-nz_417x395.webp';
import SvgInline from '@latitude/svg-inline';
import { PageContext } from '../../context/PageContext';
import { CONTENTFUL_COMPONENTS } from '../../utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { selectDataSourceForAlertBanner } from '@/utils/helpers';
import { useSetState } from '@/utils/hooks';

/*
 * Debt Consolidation Loan Page
 */

const DebtConLoanPage = () => {
  const [isNavSticky, setIsNavSticky] = useState(false);
  const { applyUrl, setApplyUrl } = useApplyUrl();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    heroBannerData: [],
    featureSliderData: [
      {
        heading: 'Why choose a Gem Loan?',
        featureCards: PageData.content.whyChooseLatitude
      }
    ]
  });

  useEffect(() => {
    let [featureSliders, heroBanners] = [[], []];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.FEATURE_SLIDER:
          const updatedItem = {
            heading: marked(item.heading || ''),
            description: ReactHTMLParser(marked(item.description || "")),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || '')
            }))
          };
          featureSliders = [...featureSliders, updatedItem];
          break;
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          heroBanners = [...heroBanners, item];
          break;
        default:
          break;
      }
    });

    setState({
      heroBannerData:
        heroBanners.length > 0 ? heroBanners : state.heroBannerData,
      featureSliderData:
        featureSliders.length > 0 ? featureSliders : state.featureSliderData
    });
  }, [contentfulPageData]);
  /** - END - */

  return (
    <Layout noMetaKeywords customFooter={footerData} hasStickyNav>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer navigation-spacer--hasStickyNav personal-loan-page">
        <Box backgroundColor={COLOR.GREY6}>
          <Helmet>
            <link
              rel="canonical"
              href="https://www.gemfinance.co.nz/loans/debt-consolidation-loans/"
            />
            <title>
              Debt Consolidation Loans | Consolidate Your Debt | Gem by gem
              finance
            </title>
            <meta
              name="description"
              content="Our debt consolidation loan in New Zealand can help you consolidate your debts into one easy to manage payment. Get a quote online in under 2 minutes."
            />
          </Helmet>
          {!selectDataSourceForAlertBanner(contentfulPageData) && (
            <NotificationBanner siteName="gemfinance-co-nz" />
          )}
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <Section
              css={`
                background-color: #0555c8;
                padding: 0;

                @media (max-width: ${BREAKPOINT.MD}) {
                  && > .container.section__content {
                    padding: 0;
                  }
                }
              `}
            >
              <Hero
                css={`
                  && {
                    color: #000;
                  }

                  #hero {
                    padding-left: 0;
                    padding-right: 0;
                  }

                  h1 {
                    color: #fff;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 600;
                    margin-top: 0;
                    font-family: Montserrat;
                  }

                  p {
                    color: #fff;
                    font-size: 19px;
                    font-weight: normal;
                    margin-right: 0;
                  }

                  svg {
                    min-height: 45px;
                    min-width: 45px;
                  }

                  .HeroContent {
                    padding: 64px 0px 117px;
                    line-height: normal;

                    &__insettext {
                      font-size: 16px;
                    }

                    &__insetdisclaimer {
                      font-size: 12px;
                    }
                  }

                  [class^='Herostyles__HeroImage-'] img {
                    height: 90%;
                  }

                  @media (max-width: ${BREAKPOINT.MD}) {
                    h1,
                    .text-left,
                    p,
                    .col-12 {
                      color: #000 !important;
                    }

                    p {
                      font-size: 20px;
                    }

                    h1 {
                      font-size: 35px;
                      margin: 5px 0;
                      white-space: normal;
                    }

                    .HeroContent {
                      padding: 10px;

                      &__insettext {
                        font-size: 14px;
                        a.button {
                          font-size: 18px;
                        }
                      }

                      &__insetdisclaimer {
                        font-size: 16px;
                      }
                    }

                    .text-center {
                      color: #000 !important;
                    }

                    [class^='Herostyles__HeroImage-'] {
                      margin-top: 25px;
                    }

                    .conditional-text {
                      display: none;
                    }
                  }
                `}
                imageContent={
                  <div
                    css={`
                      height: 100%;
                      display: flex;
                      align-items: flex-end;
                      justify-content: center;
                      @media (min-width: ${BREAKPOINT.LG}) {
                        justify-content: flex-end;
                      }
                    `}
                  >
                    <img
                      src={heroImage}
                      alt=""
                      css={`
                        height: 250px;
                        width: auto;
                        @media (min-width: ${BREAKPOINT.MD}) {
                          height: 300px;
                        }
                        @media (min-width: ${BREAKPOINT.LG}) {
                          height: 382px;
                        }
                      `}
                    />
                  </div>
                }
                className="page-banner-lfs--bg"
              >
                {
                  <div
                    id="hero"
                    css={`
                && {
                  padding: 0 15px;
                  color: #fff:

                  @media (min-width: ${BREAKPOINT.LG}) {
                    padding: 0;
                  }
                }
              `}
                  >
                    <h1>Debt Consolidation Loans</h1>
                    <HeroText
                      css={`
                        && {
                          color: #fff;
                        }
                      `}
                    >
                      Keep things simple by combining multiple repayments into
                      one. Apply for a Gem Debt Consolidation Loan online in 7
                      minutes
                      <span class="conditional-text">
                        {' '}
                        and get a response to your application in only 60
                        seconds
                      </span>
                      .
                    </HeroText>
                    <div className="row text-white HeroContent__insettext">
                      <div className="col-6 col-md-5 text-left">
                        <HomeButton
                          href={applyUrl}
                          trackId="personal-loans-button"
                          trackEventData={{
                            label: 'Get rate estimate',
                            location: 'Hero',
                            category: 'button-link'
                          }}
                          trackProductId={['PLNZLF-WEB']}
                          className="button--primary"
                        >
                          Get rate estimate
                        </HomeButton>
                      </div>
                      <div className="col-1 p-0 text-center">
                        <SvgInline name="stopwatch" />
                      </div>
                      <div className="col-5 col-md-6 text-left">
                        Check your interest rate in 2 minutes with no impact to
                        your credit score.
                      </div>
                    </div>
                    <div className="row text-white HeroContent__insetdisclaimer">
                      <div className="col-12 mt-2">
                        Credit and lending criteria, and fees apply. You may
                        wish to seek independent advice to ensure this product
                        is right for your personal circumstances. Credit
                        provided by Latitude Financial Services Limited.
                      </div>
                    </div>
                  </div>
                }
              </Hero>
            </Section>
          )}
          <SoftQuoteContext.Provider value={{ applyUrl, setApplyUrl }}>
            <div className="d-lg-none d-xl-none">
              <StickyCta
                href={applyUrl}
                trackId="sticky-get-started-debt-con"
                text="Get rate estimate"
              />
            </div>

            <div className="d-none d-lg-block">
              <StickyNavigation
                items={PageData.nav}
                phoneNumber={PageData.content.phoneNumber}
                ctaHref={applyUrl}
                ctaTarget="_self"
                ctaText="Get rate estimate"
                isSticky={isNavSticky}
                onStickyNavStateChange={setIsNavSticky}
                offsetElem="[data-sticky-navigation-offset]"
                trackId="sticky-nav-get-started"
                trackEventData={{
                  category: 'cta',
                  action: 'quote-link',
                  label: 'Get rate estimate',
                  location: 'Sticky Navigation'
                }}
                trackProductId={['PLNZLF-WEB']}
              />
            </div>

            <EstimateRateWidgetSection
              purpose={LOAN_PURPOSE.CONSOLIDATION}
              trackProductId={['PLNZLF-WEB']}
            />
          </SoftQuoteContext.Provider>

          <LoanProcessList
            title={PageData.content.loanProcess.title}
            data={PageData.content.loanProcess.list}
            id="how-it-works"
          />

          <div
            css="
              position: relative;
              z-index: 1;
              margin-top: -40px !important" /* As per UX request */
          >
            <LoanRates
              isBranded
              rateBoxType="personalSym"
              rateBoxType2="personalUnsecuredSym"
              fees={PageData.content.loanDetails1}
              extraTCs="true"
            />
          </div>

          <FeesAndCharges data={PageData.content.fees} />

          {/* css as per UX request */}
          <div
            css={`
              @media (max-width: ${BREAKPOINT.LG}) {
                padding-bottom: 50px;
              }
            `}
          >
            <CalculatorTile />
          </div>

          {state?.featureSliderData?.[0] && (
            <FeaturesSlider
              heading={state.featureSliderData[0].heading}
              subheading={state.featureSliderData[0].description}
              data={state.featureSliderData[0].featureCards}
              id="why-us"
              className="why-choose bg-f8f8f8"
              backgroundColor={COLOR.WHITE}
              css={featuresSliderIE11Fix}
            />
          )}

          <HorizontalRule />

          <HowToApplyEligibility />
          <Faq data={PageData.content.faq} autoHeightOnDesktop />
          <ImportantInformation
            data={require('../../data/pages/debt-consolidation-loans.json')}
            sectionOneColummClasses="col-10 offset-1"
            id="additional-payments"
          />
        </Box>
      </main>
    </Layout>
  );
};

const HomeButton = styled(Button)`
  && {
    padding: 15px 45px;
    background-color: #21A637;
    color: #fff!important;
    white-space: nowrap;
    font-size: 22px;
  }

  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;

export default DebtConLoanPage;
